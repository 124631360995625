<template>
  <div class="container-fluid">
    <div class="configuration-wrapper">
      <h1>{{ $t("APPS.CONFIGURATION_MODULE") }}</h1>
      <div class="kw-breadcrumb">
        <ul>
          <li>
            <img src="/img/kw-home.svg" alt="icon" />
            <span>{{ $t("APPS.CONFIGURATION_MODULE") }}</span>
          </li>
          <li v-if="$route.name === 'List SpotCategories'">
            {{ $t("CONFIGURATION.SPOT_CATEGORIES") }}
          </li>
          <li v-if="$route.name === 'List SpotTypes'">
            {{ $t("CONFIGURATION.SPOT_TYPES") }}
          </li>
          <li v-if="$route.name === 'List Equipments'">
            {{ $t("CONFIGURATION.EQUIPMENTS_LIST") }}
          </li>
          <li v-if="$route.name === 'List EquipmentCategories'">
            {{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}
          </li>
        </ul>
      </div>
      <ul class="sub-menu-items">
        <li
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SPOT_CATEGORIES)"
        >
          <router-link
            :to="{ name: 'List SpotCategories' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.SPOT_CATEGORIES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SPOT_TYPES)">
          <router-link
            :to="{ name: 'List SpotTypes' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.SPOT_TYPES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_EQUIPMENT_CATEGORIES)
          "
        >
          <router-link
            :to="{ name: 'List EquipmentCategories' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_EQUIPMENTS)">
          <router-link
            :to="{ name: 'List Equipments' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.EQUIPMENTS_LIST") }}
              </span>
            </a>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <h3>{{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}</h3>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openEquipmentCategoryCreateModal"
            v-if="
              $currentUserCan($permissions.PERM_CREATE_EQUIPMENT_CATEGORIES)
            "
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("EQUIPMENT_CATEGORIES.ADD_EQUIPMENT_CATEGORY")
            }}</span>
          </base-button>
        </div>
      </div>

      <equipment-category-list-table
        @onViewEquipmentCategory="openEquipmentCategoryViewModal"
        @onEditEquipmentCategory="openEquipmentCategoryEditModal"
        @onDeleteEquipmentCategory="deleteEquipmentCategory"
        @onAddEquipmentCategory="openEquipmentCategoryCreateModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewEquipmentCategoryModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewEquipmentCategoryModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'EQUIPMENT_CATEGORY_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeEquipmentCategoryModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("EQUIPMENT_CATEGORIES.VIEW_EQUIPMENT_CATEGORY") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-equipment-category-component
              v-if="openEquipmentCategory"
              :equipmentCategoryId="openEquipmentCategory.id"
              @onEditEquipmentCategory="openEquipmentCategoryEditModal"
              @onDeleteEquipmentCategory="deleteEquipmentCategory"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditEquipmentCategoryModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditEquipmentCategoryModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'EQUIPMENT_CATEGORY'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeEquipmentCategoryModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("EQUIPMENT_CATEGORIES.EDIT_EQUIPMENT_CATEGORY") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-equipment-category-component
              v-if="openEquipmentCategory"
              :equipmentCategoryId="openEquipmentCategory.id"
              @onViewEquipmentCategory="
                openEquipmentCategoryViewModal(openEquipmentCategory)
              "
              @onCloseEquipmentCategoryModal="closeEquipmentCategoryModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddEquipmentCategoryModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddEquipmentCategoryModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'EQUIPMENT_CATEGORY'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeEquipmentCategoryModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("EQUIPMENT_CATEGORIES.ADD_EQUIPMENT_CATEGORY") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-equipment-category-component
              @onViewEquipmentCategory="openEquipmentCategoryViewModal"
              @onCloseEquipmentCategoryModal="closeEquipmentCategoryModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
// import NotificationSubscription from "@/components/NotificationSubscription.vue";
import EquipmentCategoryListTable from "./partials/EquipmentCategoryListTable.vue";
import EditEquipmentCategoryComponent from "./components/EditEquipmentCategoryComponent.vue";
import AddEquipmentCategoryComponent from "./components/AddEquipmentCategoryComponent.vue";
import ViewEquipmentCategoryComponent from "./components/ViewEquipmentCategoryComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";

export default {
  layout: "DashboardLayout",

  components: {
    WrapperExpandButton,
    EquipmentCategoryListTable,
    // NotificationSubscription,
    EditEquipmentCategoryComponent,
    AddEquipmentCategoryComponent,
    ViewEquipmentCategoryComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const equipmentCategoryId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewEquipmentCategoryModalOpened = false;
    let isEditEquipmentCategoryModalOpened = false;
    let isAddEquipmentCategoryModalOpened = false;
    let openEquipmentCategory = null;
    if (equipmentCategoryId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewEquipmentCategoryModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditEquipmentCategoryModalOpened = true;
      }
      openEquipmentCategory = { id: equipmentCategoryId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddEquipmentCategoryModalOpened = true;
    }
    return {
      isViewEquipmentCategoryModalOpened: isViewEquipmentCategoryModalOpened,
      isEditEquipmentCategoryModalOpened: isEditEquipmentCategoryModalOpened,
      isAddEquipmentCategoryModalOpened: isAddEquipmentCategoryModalOpened,
      openEquipmentCategory: openEquipmentCategory,
      renderKey: 1,
    };
  },

  methods: {
    openEquipmentCategoryCreateModal() {
      this.closeEquipmentCategoryModal();
      this.isAddEquipmentCategoryModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List EquipmentCategories",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    openEquipmentCategoryViewModal(equipmentCategory, reRender = false) {
      this.closeEquipmentCategoryModal();
      this.openEquipmentCategory = equipmentCategory;
      this.isViewEquipmentCategoryModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }
      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List EquipmentCategories",
          query: {
            id: this.openEquipmentCategory.id,
            action: QUERY_ACTIONS_VIEW,
          },
        }).href
      );
    },

    openEquipmentCategoryEditModal(equipmentCategory) {
      this.closeEquipmentCategoryModal();
      this.openEquipmentCategory = equipmentCategory;
      this.isEditEquipmentCategoryModalOpened = true;
      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List EquipmentCategories",
          query: {
            id: this.openEquipmentCategory.id,
            action: QUERY_ACTIONS_EDIT,
          },
        }).href
      );
    },

    closeEquipmentCategoryModal() {
      this.isAddEquipmentCategoryModalOpened = false;
      this.isViewEquipmentCategoryModalOpened = false;
      this.isEditEquipmentCategoryModalOpened = false;
      this.openEquipmentCategory = null;
      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List EquipmentCategories",
          query: {},
        }).href
      );
    },

    async deleteEquipmentCategory(equipmentCategory) {
      const confirmation = await swal.fire({
        text: this.$t("EQUIPMENT_CATEGORIES.DELETE_THIS_EQUIPMENT_CATEGORY"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "equipmentCategories/destroy",
            equipmentCategory.id
          );
          this.renderKey++;
          this.closeEquipmentCategoryModal();
          this.$notify({
            type: "success",
            message: this.$t("EQUIPMENT_CATEGORIES.EQUIPMENT_CATEGORY_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
