<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!equipmentCategory">
      <span class="loader"></span>
    </span>
    <div v-if="equipmentCategory" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>
            {{ $t("EQUIPMENT_CATEGORIES.DETAILS_VIEW_EQUIPMENT_CATEGORY") }}
          </h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}</span>
              </li>
              <li>
                <span>{{ equipmentCategory.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button
            class="delete"
            @click="deleteEquipmentCategory"
            v-if="
              $currentUserCan($permissions.PERM_DELETE_EQUIPMENT_CATEGORIES)
            "
          >
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE_INFOS") }}</span>
          </button>
          <button
            class="edit"
            @click="editEquipmentCategory"
            v-if="$currentUserCan($permissions.PERM_EDIT_EQUIPMENT_CATEGORIES)"
          >
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT_INFOS") }}</span>
          </button>
        </div>
      </div>

      <modal
        :show="isAddEquipmentModalOpened"
        modal-classes="modal-secondary equipment"
        size="lg"
      >
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <add-equipment-component
              v-if="equipmentCategory"
              :defaultCategoryId="equipmentCategory.id"
              @onViewEquipment="closeEquipmentModal"
              @onCloseEquipmentModal="closeEquipmentModal"
              :key="renderKey * 100"
            />
          </div>
        </div>
      </modal>

      <modal
        :show="isEditEquipmentModalOpened"
        modal-classes="modal-secondary equipment"
        size="lg"
      >
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <edit-equipment-component
              v-if="openEquipment"
              :equipmentId="openEquipment.id"
              @onViewEquipment="closeEquipmentModal"
              @onCloseEquipmentModal="closeEquipmentModal"
              :key="renderKey * 200"
            />
          </div>
        </div>
      </modal>

      <modal
        :show="isViewEquipmentModalOpened"
        modal-classes="modal-secondary equipment"
        size="lg"
      >
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <view-equipment-component
              v-if="openEquipment"
              :equipmentId="openEquipment.id"
              @onEditEquipment="openEquipmentEditModal"
              @onDeleteEquipment="deleteEquipment"
            />
          </div>
        </div>
        <template slot="footer">
          <button type="button" class="btn" @click="closeEquipmentModal">
            {{ $t("COMMON.CLOSE") }}
          </button>
        </template>
      </modal>

      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="equipmentCategory"
      >
        <tab-pane title="equipmentCategory" id="1" :active="true">
          <span slot="title">
            {{ $t("COMMON.GENERAL_INFORMATIONS") }}
          </span>
          <equipment-category-view-global
            :equipmentCategory="equipmentCategory"
          />
        </tab-pane>

        <tab-pane title="equipments" id="2" :active="false">
          <span slot="title">
            {{ $t("EQUIPMENTS.EQUIPMENT_LIST") }}
          </span>
          <equipment-list-table
            ref="equipmentListTable"
            :filterCategory="equipmentCategory.id"
            @onAddEquipment="openEquipmentCreateModal"
            @onEditEquipment="openEquipmentEditModal"
            @onViewEquipment="openEquipmentViewModal"
            @onEquipmentDeleted="closeEquipmentModal"
            :key="renderKey * 400"
          />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import EquipmentCategoryViewGlobal from "../partials/EquipmentCategoryViewGlobal.vue";
import EquipmentListTable from "../../EquipmentManagement/partials/EquipmentListTable.vue";
import AddEquipmentComponent from "../../EquipmentManagement/components/AddEquipmentComponent.vue";
import EditEquipmentComponent from "../../EquipmentManagement/components/EditEquipmentComponent.vue";
import ViewEquipmentComponent from "../../EquipmentManagement/components/ViewEquipmentComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ViewEquipmentComponent,
    EditEquipmentComponent,
    AddEquipmentComponent,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    EquipmentCategoryViewGlobal,
    EquipmentListTable,
  },

  mixins: [],

  props: {
    equipmentCategoryId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      equipmentCategory: null,
      isViewEquipmentModalOpened: false,
      isEditEquipmentModalOpened: false,
      isAddEquipmentModalOpened: false,
      openEquipment: null,
      renderKey: 1,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "equipmentCategories/get",
          this.equipmentCategoryId
        );
        this.equipmentCategory =
          this.$store.getters["equipmentCategories/equipmentCategory"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editEquipmentCategory() {
      this.$emit("onEditEquipmentCategory", this.equipmentCategory);
    },

    deleteEquipmentCategory() {
      this.$emit("onDeleteEquipmentCategory", this.equipmentCategory);
    },

    closeEquipmentModal() {
      this.isAddEquipmentModalOpened = false;
      this.isViewEquipmentModalOpened = false;
      this.isEditEquipmentModalOpened = false;
      this.openEquipment = null;
      this.renderKey++;
    },
    openEquipmentEditModal(equipment) {
      this.openEquipment = equipment;
      this.isAddEquipmentModalOpened = false;
      this.isViewEquipmentModalOpened = false;
      this.isEditEquipmentModalOpened = true;
    },
    openEquipmentCreateModal() {
      this.isAddEquipmentModalOpened = true;
      this.isViewEquipmentModalOpened = false;
      this.isEditEquipmentModalOpened = false;
    },
    openEquipmentViewModal(equipment) {
      this.openEquipment = equipment;
      this.isAddEquipmentModalOpened = false;
      this.isViewEquipmentModalOpened = true;
      this.isEditEquipmentModalOpened = false;
    },
    deleteEquipment(equipment) {
      this.$refs.equipmentListTable.deleteEquipment(equipment.id);
    },
  },
};
</script>
