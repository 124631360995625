<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row" v-if="equipmentCategory.name">
          <dt>
            {{ $t("EQUIPMENT_CATEGORIES.EQUIPMENT_CATEGORY_NAME") }}
          </dt>
          <dd>
            {{ equipmentCategory.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("CONFIGURATION.SHOW_AS_FILTER") }}</dt>
          <dd>
            {{ equipmentCategory.show_as_filter ? $t('COMMON.YES') : $t('COMMON.NO') }}
          </dd>
        </dl>

        <dl class="row" v-if="equipmentCategory.color">
          <dt>{{ $t("COMMON.COLOR") }}</dt>
          <dd>
            {{ equipmentCategory.color }}
          </dd>
        </dl>

        <dl class="row full" v-if="equipmentCategory.excerpt">
          <dt>
            {{ $t("COMMON.COMMENT") }}
          </dt>
          <dd>
            <div v-html="equipmentCategory.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ equipmentCategory.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ equipmentCategory.updated_at | moment("LLLL") }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "equipment-category-view-global",

  props: ["equipmentCategory"],

  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    contactUpdated() {
      this.$emit("equipmentCategoryUpdated", true);
    },
  },

  mounted() {},

  watch: {
    contact(contact) {},
  },
};
</script>
